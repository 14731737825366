<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-3" title="Edit Tanggapan" ok-only ok-title="Accept" hide-footer no-close-on-backdrop
            size="xl"> 
            <b-row>
                <b-col md="7">
                    <b-alert variant="" show>
                        <div class="alert-body">
                            <h4 class="text-primary">
                                <feather-icon icon="UsersIcon" size="15" /> Aduan Masyarakat</h4>
                            <b-row>
                                <b-col md="12">
                                    <h6><small>Judul Aduan</small></h6>
                                    <h5>{{ rsDetail.judul_aduan }}</h5>
                                    <h6><small>kategori Pengaduan {{ rsDetail.jenis_aduan }}</small></h6>
                                    <b-alert variant="warning" show>
                                        <div class="alert-body">
                                            <h6><small>Deskripsi</small></h6>
                                            <h6 style="text-align:justify;"><small>{{ rsDetail.deskripsi }}</small>
                                            </h6>
                                            <h6> <small class="text-primary"> <i>Pelapor {{ rsDetail.nm_lengkap }} ||
                                                        <a :href="'https://wa.me/'+ rsDetail.cp"
                                                            target="_blank">{{ rsDetail.no_hp }}</a></i></small></h6>
                                        </div>
                                    </b-alert>
                                    <h6>
                                        <b-button variant="outline-warning" block>
                                            <feather-icon icon="MapPinIcon" size="15" />Lokasi {{ rsDetail.nm_wil }}
                                            <span v-if="rsDetail.koordinat != null ">| Koordinat
                                                {{ rsDetail.koordinat }}</span> </b-button>
                                    </h6>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="text-primary">
                                    <feather-icon icon="ImageIcon" size="15" /> Foto Pengaduan</b-col>
                                <b-col md="6" v-if="rsDetail.foto_1 != ''">
                                    <b-img thumbnail fluid :src="rsDetail.foto_1" />
                                </b-col>
                                <b-col md="6" v-if="rsDetail.foto_2 != ''">
                                    <b-img thumbnail fluid :src="rsDetail.foto_2" />
                                </b-col>
                                <b-col md="6" v-if="rsDetail.foto_3 != ''">
                                    <b-img thumbnail fluid :src="rsDetail.foto_3" />
                                </b-col>
                                <b-col md="6" v-if="rsDetail.foto_4 != ''">
                                    <b-img thumbnail fluid :src="rsDetail.foto_4" />
                                </b-col>
                            </b-row>
                        </div>
                    </b-alert>
                </b-col>
                <b-col md="5">
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <h4 class="text-primary"> Jawaban Aduan Masyarakat
                                <strong>
                                    <feather-icon icon="MessageSquareIcon" size="25" /></strong>
                            </h4>

                            <tanggapan_edit :rsDetail=rsDetail @loadData=loadData></tanggapan_edit>
                            <b-button variant="warning" type="submit" @click="close()" block class="ml-l">
                                <feather-icon icon="XCircleIcon" size="15"></feather-icon> Batal
                            </b-button>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BRow,
        BCol,
        BImg
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import tanggapan_edit from './tanggapan_edit.vue'

    export default {
        data() {
            return {
                sideImg: require('@/assets/images/pages/coming-soon.svg'),
            }
        },
        components: {
            BButton,
            BModal,
            BAlert,
            BRow,
            BCol,
            BImg,
            tanggapan_edit
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        props: {
            rsDetail: {

            }
        },
        emits: ["loadData"],
        methods: {
            loadData() {
                this.$root.$emit('bv::hide::modal', 'modal-3');
                this.$emit("loadData")
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-3');
            }
        }
    }
</script>