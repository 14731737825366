<template>
    <!-- form -->
    <validation-observer ref="simpleRules">
        <b-form> 
            <b-row>
                <b-col md="12">
                    <!-- <b-form-group label="Status">
                        <validation-provider #default="{ errors }" name="Status Tanggapan " rules="required">
                            <b-form-select :state="errors.length > 0 ? false:null" v-model="input."
                                :options="options" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group> -->

                    <b-form-group label="Jawaban">
                        <validation-provider #default="{ errors }" name="Jawaban Tanggapan" rules="required">
                            <b-form-textarea id="textarea-default" :state="errors.length > 0 ? false:null"
                                v-model="jawaban" placeholder="Jawaban Tanggapan" rows="3"></b-form-textarea>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Foto Jawaban">
                        <b-form-file v-model="file" placeholder="Choose a file or drop it here..." ref="file"
                            id="customFile" @input="uploadFile" drop-placeholder="Drop file here..." />


                    </b-form-group>
                    <b-form-group label="Foto Jawaban">
                        <b-form-file v-model="file2" placeholder="Choose a file or drop it here..." ref="file2"
                            id="customFile2" @input="uploadFile2" drop-placeholder="Drop file here..." />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-button variant="primary" type="submit" block @click.prevent="validationForm" class="mr-1 mb-1">
                        <feather-icon icon="SaveIcon"></feather-icon> Submit
                    </b-button>

                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</template>

<script>
    import axios from '@/config/Axios'
    import {
        ref
    } from '@vue/composition-api'

    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BFormSelect,
        BFormFile
    } from 'bootstrap-vue'
    import {
        required,
        email
    } from '@validations'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
    import Base from '@/config/Mixins_base'

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
            BCardText,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BButton,
            BFormTextarea,
            BFormSelect,
            BFormFile,
            FeatherIcon
        },
        mixins: [Base],
        data() {
            return {
                emailValue: '',
                name: '',
                jawaban: {},
                required,
                email,
                file: {},
                file2: {},
                fileUpload: {},
                fileUpload2: {},
                options: [{
                        value: null,
                        text: 'Please select an option'
                    },
                    {
                        value: '1',
                        text: 'Sudah Ditanggapi'
                    },
                    {
                        value: '0',
                        text: 'Belum Ditanggapi'
                    },

                ],
            }
        },
        props:{
            rsDetail : {}
        },
        mixins : [Base],
        emits:["loadData"],
        methods: {
            uploadFile() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file = this.$refs.file.files[0];
                let formData = new FormData();
                formData.append('file', this.file);
                const self = this;
                axios.post('/upload/tanggapan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file2 = {};
                        self.fileUpload = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },
            uploadFile2() {
                const isAuthenticated = localStorage.getItem('token_auth');
                this.file2 = this.$refs.file2.files[0];
                let formData = new FormData();
                formData.append('file', this.file2);
                const self = this;
                axios.post('/upload/tanggapan', formData, {
                        headers: {
                            'Authorization': isAuthenticated,
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(function (response) {
                        self.file2 = {};
                        self.fileUpload2 = response.data.result.nm_file

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            },

            async simpanData() {
                const isAuthenticated = localStorage.getItem('token_auth');
                    const self = this;
                    await axios({
                        method: 'POST',
                                url: '/api/pengaduan/daftar/tanggapi_aduan',
                                data: {
                                    jawaban : self.jawaban,
                                    foto_respon1 : self.fileUpload,
                                    foto_respon2 : self.fileUpload2,
                                    id : self.rsDetail.id
                                },
                        headers: {
                          'Authorization': isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.notification('info', "Tanggapan ", response.data.message);
                        self.$emit("loadData");
            
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },


            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) { 
                        this.simpanData();
                    }
                })
            },

        },
        setup() {
            const refInputEl = ref(null)
            const previewEl = ref(null)
            const {
                inputImageRenderer
            } = useInputImageRenderer(refInputEl, previewEl)
            return {
                refInputEl,
                previewEl,
                inputImageRenderer,
            }
        },
    }
</script>