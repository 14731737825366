<template>
    <b-row> 
        <b-col md="12">
            <h4 class="text-primary" v-if="status =='Ditanggapi'"><feather-icon icon ="CheckCircleIcon" size ="20" ></feather-icon> Sudah Ditanggapi</h4>
            <h4 class="text-primary" v-if="status ==''"><feather-icon icon ="LayersIcon" size ="20"></feather-icon> Semua Aduan</h4>
            <h4 class="text-warning" v-if="status =='Pengaduan'"><feather-icon icon ="BellIcon" size ="20"></feather-icon> Belum Ditanggapi</h4>
            
        </b-col>
        <b-col md="3" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="3" sm="8" class="my-1">
        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(aduan)="data">
                    <!-- {{ data.item }} -->
                    <b-row class="mlm-5 ">
                        <b-col md="7">
                            <h6><strong class="text-primary">Judul {{ data.item.judul_aduan }}</strong></h6>
                            <h6 v-if="data.item.status !='Ditanggapi'"><small><small class="text-warning">Kategori {{ data.item.jenis_aduan }}</small>
                                </small></h6>
                            <h6 v-if="data.item.status =='Ditanggapi'"><small><small class="text-primary">Kategori  {{ data.item.jenis_aduan}}</small>
                                </small></h6>


                            <p style="text-align:justify"><small>{{ data.item.deskripsi }}</small>
                            </p>
                            <small class="text-primary"> <i>Pelapor {{ data.item.nm_lengkap }} || 
                            <a :href="'https://wa.me/'+ data.item.cp" target="_blank">{{ data.item.no_hp }}</a></i></small>

                        </b-col>
                        <b-col md="3">
                            <h6><small>Lokasi</small></h6>
                            <h6><small><strong>
                                        <feather-icon icon="MapPinIcon" size="15" /> {{ data.item.nm_wil }}</strong></small></h6>
                            <h6><small>Tanggal Pengaduan</small></h6>
                            <h6><small><strong>{{ data.item.dc }}</strong></small></h6>
                            <b-button v-b-modal.modal-2 block variant="primary" size="sm" v-if="data.item.status =='Ditanggapi'" @click="detailtanggapan(data.item)">
                                <feather-icon icon="CheckCircleIcon" size="15" /> Ditanggapi</b-button>
                            <b-button v-b-modal.modal-1 block variant="warning" size="sm" v-if="data.item.status !='Ditanggapi'" @click="tanggapi(data.item)">
                                <feather-icon icon="AlertCircleIcon" size="15" /> Belum Ditanggapi</b-button>

                        </b-col>
                        <b-col md="2">
                            <b-button block variant="info" size="sm" v-b-modal.modal-1 v-if="data.item.status !='Ditanggapi'" @click="tanggapi(data.item)">
                                <feather-icon icon="MessageSquareIcon" size="15" /> Tanggapi</b-button>

                            <b-button block variant="primary" size="sm" v-b-modal.modal-3 v-if="data.item.status =='Ditanggapi'" @click="editTanggapan(data.item)">
                                <feather-icon icon="EditIcon" size="15" /> Edit Tanggapan </b-button>
                            <b-button block variant="danger" size="sm" @click="deleteData(data.item)">
                                <feather-icon icon="Trash2Icon" size="15" /> Delete</b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="6">
            Total Row {{ items.length }}
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
                FeatherIcon,
        },
        props : {
            items : {},
            status : {}
        },
        data() {
            return {
                perPage: 3,
                pageOptions: [3, 5, 10, 100, 1000, 10000],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                fields: [{
                    key: 'aduan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }], 

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        emits :["detailtanggapan", "tanggapi", "editTanggapan", "deleteData"],
        methods: {
            detailtanggapan(value){
                this.$emit("detailtanggapan",value);
            },
            tanggapi(value){
                this.$emit("tanggapi",value);
            },
            editTanggapan(value){
                this.$emit("editTanggapan",value);
            },
            deleteData(value){
                if(confirm("Apakan anda yakin akan hapus data ini") == true){
                    this.$emit("deleteData",value);
                }
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>