<template>
    <div>
        <b-row>
            <b-col md="12"> 
                <b-card class="card-congratulations bg-primary match-height" @click="act_data('')">
                    <b-row>
                        <b-col md="3" sm="3" lg="3" xs='3'>
                            <feather-icon class="text-warning" icon="MessageCircleIcon" size="55" />
                        </b-col>
                        <b-col md="9" sm="9" lg="9" xs="9">
                            <blockquote class="blockquote mb-0">
                                <h5 class="text-warning">Total Aduan
                                </h5>
                                <h1><strong class="text-warning">{{ ttl_aduan.all }} <small>Aduan</small></strong></h1>
                            </blockquote>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <small class="text-muted"><i>** Klik untuk detail data </i></small>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card class="card-congratulations bg-danger match-height" @click="act_data('Ditanggapi')">
                    <b-row>
                        <b-col md="3" sm="3" lg="3" xs='3'>
                            <feather-icon class="text-warning" icon="CheckCircleIcon" size="55" />
                        </b-col>
                        <b-col md="9" sm="9" lg="9" xs="9">
                            <blockquote class="blockquote mb-0">
                                <h5 class="text-warning">Sudah Ditanggapi
                                </h5>
                                <h1><strong class="text-warning">{{ ttl_aduan.ditanggapi }}
                                        <small>Aduan</small></strong></h1>
                            </blockquote>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <small class="text-muted"><i>** Klik untuk detail data </i></small>
                        </b-col>
                    </b-row>
                </b-card>

                <b-card class="card-congratulations-warning bg-danger match-height" @click="act_data('Pengaduan')">
                    <b-row>
                        <b-col md="3" sm="3" lg="3" xs='3'>
                            <feather-icon icon="AlertTriangleIcon" size="55" />
                        </b-col>
                        <b-col md="9" sm="9" lg="9" xs="9">
                            <blockquote class="blockquote mb-0">
                                <h5 class="text-white">Belum Ditanggapi
                                </h5>
                                <h1 v-if=" ttl_aduan.belum_ditanggapi != null "><strong
                                        class="text-white">{{ ttl_aduan.belum_ditanggapi }}
                                        <small>Aduan</small></strong></h1>
                                <h1 v-if=" ttl_aduan.belum_ditanggapi == null "><strong class="text-white">0
                                        <small>Aduan</small></strong></h1>
                            </blockquote>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <small class="text-muted"><i>** Klik untuk detail data </i></small>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BButton
    } from 'bootstrap-vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton
        },
        props: {
            ttl_aduan: {}
        },
        emits: ['act_data'],
        methods: {
            act_data(value) {
                this.$emit('act_data', value);
            }
        }

    }
</script>

<style>

</style>