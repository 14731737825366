<template>
  <div>
    <b-row>
      <b-col md="3">
        <rekap :ttl_aduan=ttl_aduan @act_data=act_data></rekap>
      </b-col>
      <b-col md="9">
        <b-card>
          <tabel :items=items :status=status @detailtanggapan=detailtanggapan @tanggapi=tanggapi
            @editTanggapan=editTanggapan @deleteData=deleteData></tabel>
        </b-card>
      </b-col>
    </b-row>
    <detail :rsDetail=rsDetail @loadData=dataLoads></detail>
    <detail_tanggapan :rsDetail=rsDetail @loadData=dataLoads></detail_tanggapan>
    <edit_tanggapan :rsDetail=rsDetail @loadData=dataLoads></edit_tanggapan>
  </div>
</template>

<script>
  import Base from '@/config/Mixins_base';
  import axios from '@/config/Axios';
  import {
    BRow,
    BCol,
    BCard,
    BButton
  } from 'bootstrap-vue'
  import rekap from './component/rekap.vue'
  import tabel from './component/tabel.vue';
  import detail from './component/detail.vue';
  import detail_tanggapan from './component/detail_tanggapan.vue';
  import edit_tanggapan from './component/editTanggapan';
  export default {
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      rekap,
      tabel,
      detail,
      detail_tanggapan,
      edit_tanggapan

    },
    mixins: [Base],
    data() {
      return {
        items: [],
        status: '',
        ttl_aduan: {},
        rsDetail: {}
      }
    },
    mounted() {
      this.cek_token();
      this.loadData();
      this.jm_aduan();
    },
    methods: {
      dataLoads() { 
        this.loadData();
        this.jm_aduan();
      },
      act_data(value) {
        this.status = value;
        this.loadData();
      },
      simpan() {
        this.loadData();
      },
      detailtanggapan(value) {
        this.$root.$emit('bv::show::modal', 'modal-2');
        this.rsDetail = value
      },
      tanggapi(value) {
        this.$root.$emit('bv::show::modal', 'modal-1');
        this.rsDetail = value
        this.rsDetail = value
      },
      editTanggapan(value) {
        this.$root.$emit('bv::show::modal', 'modal-3');
        this.rsDetail = value
        this.rsDetail = value
      },
      async deleteData(value) { 
        const self = this;
        await axios({
            method: 'POST',
            url: '/api/pengaduan/daftar/hapus_aduan',
            data: {
              id: value.id
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.notification('info', "info", response.data.message);
            self.loadData();

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async jm_aduan(value) {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pengaduan/daftar/jm_aduan',
            data: {
              status: value
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.ttl_aduan = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async loadData() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/pengaduan/daftar/load',
            data: {
              status: self.status
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.items = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }

  }
</script>

<style>

</style>